import { FullStory, init } from "@fullstory/browser";
import config from "@/config";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import type { IClient } from "@/models/clients";
import type { IUser } from "@/models/users";
import type { FullStoryEvent } from "@/enums/events";

export const useFullStory = () => {
  const orgId = config.full_story.org_id;
  const { getters } = useStore();
  const fullStoryActive = ref(false);

  const fullStoryShutdown = () => {
    FullStory("shutdown");
    fullStoryActive.value = false;
  };

  const fullStoryRestart = () => {
    FullStory("restart");
    fullStoryActive.value = true;
  };

  const initFullStory = (): void => {
    if (orgId) {
      init({
        orgId,
        recordCrossDomainIFrames: true,
        devMode: config.plaid.environment !== "production"
      });
      fullStoryShutdown();

      const user = computed<IUser>(() => getters["auth/user"]);
      const authClientSettings = computed<IClient>(
        () => getters["auth/authClientSettings"]
      );

      watch(user, (value) => {
        if (!value) {
          fullStoryShutdown();
          return;
        }

        fullStoryRestart();
        FullStory("setIdentity", {
          uid: String(value?.id),
          properties: {
            displayName:
              value?.full_name ?? `${value?.first_name} ${value?.last_name}`,
            email: value?.email ?? "",
            Roles: value?.roles ?? [],
            ClientId: value?.client_id,
            Source: "dashboard"
          }
        });
      });

      watch(authClientSettings, (value: IClient) => {
        if (fullStoryActive.value) {
          FullStory("setProperties", {
            type: "user",
            properties: {
              ClientName: value?.name ?? "Not a client"
            }
          });
        }
      });
    }
  };

  const publishEvent = (
    browserEvent: FullStoryEvent,
    options: Record<string, unknown> = {}
  ) => {
    FullStory("trackEvent", { name: browserEvent, properties: options });
  };

  return {
    initFullStory,
    publishEvent
  };
};
